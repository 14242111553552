<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Turnos</h5>
              </div>

              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Tep</li>
                  <li class="breadcrumb-item active">Internos</li>
                  <li class="breadcrumb-item active">Operaciones</li>
                  <li class="breadcrumb-item active">Turnos</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-11"></div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <!-- SE LLAMAN BOTONES ACA -->
                      <button
                        type="button"
                        class="btn btn-info"
                        @click="pivotTable()"
                      >
                        <i class="fas fa-scroll"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal-form-Tepturno-export"
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table
                  id="users"
                  class="
                    table table-bordered table-striped table-hover table-sm
                    text-nowrap
                  "
                  style="font-size: 0.8em"
                >
                  <thead>
                    <tr>
                      <th>
                        <button
                          class="btn btn-primary btn-sm"
                          style="cursor: pointer"
                          data-toggle="modal"
                          data-target="#modal-form-crear-tepTurno"
                          v-if="$store.getters.can('tep.turnos.create')"
                          @click="llenarModalCrear()"
                        >
                          <i class="fas fa-plus"></i>
                        </button>
                      </th>
                      <th>
                        #
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="filtros.id_turno"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Sitio
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.sitio"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Conductor
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.conductor"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Cédula
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="filtros.cedula"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Conductor 2
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.conductor2"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Cédula 2
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="filtros.cedula2"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Empresa
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="selct_empresa"
                          placeholder="Empresas"
                          label="razon_social"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.empresas"
                          :filterable="false"
                          @search="buscarEmpresas"
                          @input="getIndex()"
                        ></v-select>
                      </th>
                      <th colspan="2">
                        Vehículo
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.vehiculo"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>Postulación</th>
                      <th>
                        Fecha
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filtros.fecha"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Inicio
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.inicio"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option value="Web">Web</option>
                          <option value="App">App</option>
                        </select>
                      </th>
                      <th>
                        Fin
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.fin"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option value="Web">Web</option>
                          <option value="App">App</option>
                        </select>
                      </th>
                      <th>
                        Turno
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="select_turno"
                          placeholder="Turno"
                          label="nombre"
                          :options="listasForms.turnos"
                          @input="getIndex()"
                        ></v-select>
                      </th>
                      <th>
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>
                        Estado preliquidacion
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estadopre"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estadopre in listasForms.estadospre"
                            :key="estadopre.numeracion"
                            :value="estadopre.numeracion"
                          >
                            {{ estadopre.descripcion }}
                          </option>
                          <option value="5"> No liquidado</option>
                        </select>
                      </th>
                      <th>
                        Tipo Operación
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.tipo_operacion"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="tipo_operacion in listasForms.tipo_operacion"
                            :key="tipo_operacion.numeracion"
                            :value="tipo_operacion.numeracion"
                          >
                            {{ tipo_operacion.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>
                        Estado Inspección
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado_inspeccion"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado_inspeccion in listasForms.estado_inspeccion"
                            :key="estado_inspeccion.numeracion"
                            :value="estado_inspeccion.numeracion"
                          >
                            {{ estado_inspeccion.descripcion }}
                          </option>
                        </select>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in turnos.data" :key="item.id">
                      <td>
                        <div class="btn-group dropright">
                          <button
                            type="button"
                            class="btn btn-dark btn-sm"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            :disabled="item.estado == 5"
                          >
                            <i class="fas fa-align-justify"></i>
                          </button>
                          <div class="dropdown-menu">
                            <a
                              id="botonImp"
                              type="button"
                              class="dropdown-item"
                              style="cursor: pointer"
                              v-if="
                                $store.getters.can('tep.turnos.odometroInicial')
                              "
                              @click="editOdometroInicial(item)"
                            >
                              <i class="fas fa-camera-retro"></i>
                              Diligenciar Odometro Inicial
                            </a>
                            <a
                              id="botonImp"
                              type="button"
                              class="dropdown-item"
                              style="cursor: pointer"
                              v-if="
                                $store.getters.can(
                                  'tep.turnos.odometroFinal'
                                ) &&
                                  item.link_odometro_ini !== null &&
                                  item.odometro_ini !== null
                              "
                              @click="editOdometroFinal(item)"
                            >
                              <i class="fas fa-camera-retro"></i>
                              Diligenciar Odometro Final
                            </a>
                            <a
                              class="dropdown-item"
                              style="cursor: pointer"
                              data-toggle="modal"
                              data-target="#modal-form-turno-entregar"
                              v-if="
                                $store.getters.can('tep.turnos.edit') &&
                                  item.estado == 1 &&
                                  item.postulacion.conductor_2_id != null &&
                                  item.fecha_cambio == null
                              "
                              @click="llenarModalEntregarTurno(item)"
                            >
                              <i class="fas fa-people-arrows"></i>
                              Entregar Turno
                            </a>
                            <a
                              class="dropdown-item"
                              style="cursor: pointer"
                              data-toggle="modal"
                              data-target="#modal-form-cambio-tepconductor"
                              v-if="
                                $store.getters.can(
                                  'tep.turnos.cambiarConductor'
                                ) &&
                                  item.estado != 2 &&
                                  item.estado != 5
                              "
                              @click="llenarModalCambiarConductor(item)"
                            >
                              <i class="fas fa-people-arrows"></i>
                              Cambiar Conductor
                            </a>
                            <a
                              class="dropdown-item"
                              style="cursor: pointer"
                              data-toggle="modal"
                              data-target="#modal-form-cambio-tepvehiculo"
                              v-if="
                                $store.getters.can(
                                  'tep.turnos.cambiarVehiculo'
                                ) &&
                                  item.estado != 2 &&
                                  item.estado != 5
                              "
                              @click="llenarModalCambiarVehiculo(item)"
                            >
                              <i class="fas fa-arrows-alt-h"></i>
                              Cambiar Vehículo
                            </a>
                            <a
                              class="dropdown-item"
                              style="cursor: pointer"
                              data-toggle="modal"
                              data-target="#modal-form-turno-cambio-ttrans"
                              @click="
                                $refs.TepTurnoCACambioTTrans.llenar_modal(item)
                              "
                              v-if="
                                $store.getters.can(
                                  'tep.turnos.cambiarTipoTransporte'
                                ) &&
                                  item.estado != 2 &&
                                  item.estado != 5
                              "
                            >
                              <i class="fas fa-map-marked"></i>
                              Convertir en Nacional
                            </a>
                            <a
                              class="dropdown-item"
                              style="cursor: pointer"
                              data-toggle="modal"
                              data-target="#modal-form-finalizacion-tepTurno"
                              v-if="
                                $store.getters.can('tep.turnos.finalizar') &&
                                  item.estado != 2 &&
                                  item.estado != 3 &&
                                  item.odometro_ini != null &&
                                  item.odometro_fin != null &&
                                  item.link_odometro_ini != null &&
                                  item.link_odometro_fin != null &&
                                  item.estado_inspeccion == 1
                              "
                              @click="
                                llenarModalFinalizar(
                                  item.id,
                                  item.vehiculo,
                                  item.fecha_ini
                                )
                              "
                            >
                              <i class="fas fa-check"></i>
                              Finalizar Turno
                            </a>
                            <a
                              class="dropdown-item"
                              data-toggle="modal"
                              data-target="#modal-form-tep-inspeccion"
                              style="cursor: pointer"
                              v-if="
                                $store.getters.can('tep.turnos.inspeccionar') &&
                                  item.odometro_ini != null &&
                                  item.estado == 3 &&
                                  item.estado_inspeccion != 3
                              "
                              @click="llenarModalInspeccion(item, 1)"
                            >
                              <i class="fas fa-binoculars"></i>
                              Diligenciar Inspeccion
                            </a>
                            <a
                              class="dropdown-item"
                              data-toggle="modal"
                              data-target="#modal-form-tep-cancelacion"
                              style="cursor: pointer"
                              v-if="
                                $store.getters.can('tep.turnos.cancelar') &&
                                  item.estado != 2 &&
                                  item.estado != 5
                              "
                              @click="llenarModalCancelar(item)"
                            >
                              <i class="fas fa-ban"></i>
                              Cancelar Turno
                            </a>
                          </div>
                        </div>
                      </td>
                      <td class="text-center">
                        <button
                          type="button"
                          class="btn btn-sm bg-light"
                          data-toggle="modal"
                          data-target="#modal-form-detalle-tepTurno"
                          style="cursor: pointer"
                          v-if="$store.getters.can('tep.turnos.detalle')"
                          @click="llenarModal(item)"
                        >
                          {{ item.id }}
                        </button>
                      </td>
                      <td v-if="item.sitio">{{ item.sitio.nombre }}</td>
                      <td v-if="item.conductor">
                        {{ item.conductor.nombres + " "
                        }}{{ item.conductor.apellidos }}
                      </td>
                      <td v-else></td>
                      <td v-if="item.conductor">
                        {{ item.conductor.numero_documento }}
                      </td>
                      <td v-else></td>
                      <td v-if="item.conductor2">
                        {{ item.conductor2.nombres + " "
                        }}{{ item.conductor2.apellidos }}
                      </td>
                      <td v-else></td>
                      <td v-if="item.conductor2">
                        {{ item.conductor2.numero_documento }}
                      </td>
                      <td v-else></td>
                      <td v-if="item.empresa">
                        {{ item.empresa.razon_social }}
                      </td>
                      <td v-else></td>
                      <td colspan="1" v-if="item.vehiculo">
                        {{ item.vehiculo.placa }}
                      </td>
                      <td colspan="1" v-else></td>
                      <td colspan="1">
                        <div
                          v-if="
                            item.vehiculo &&
                              item.vehiculo.ultimo_registro &&
                              item.vehiculo.ultimo_registro !== undefined
                          "
                        >
                          <i
                            v-if="
                              item.vehiculo.ultimo_registro
                                .fecha_hora_ultimo_reporte < fechaCorte
                            "
                            class="fa fa-times-circle text-danger"
                            data-toggle="tooltip"
                            data-placement="right"
                            :title="
                              item.vehiculo.ultimo_registro
                                .fecha_hora_ultimo_reporte
                            "
                          ></i>

                          <i
                            v-else
                            class="fa fa-check text-success"
                            data-toggle="tooltip"
                            data-placement="right"
                            :title="
                              item.vehiculo.ultimo_registro
                                .fecha_hora_ultimo_reporte
                            "
                          ></i>
                        </div>
                        <div v-else>
                          <i
                            class="fa fa-times-circle text-danger"
                            data-toggle="tooltip"
                            data-placement="right"
                            title="Sin fecha de reporte"
                          ></i>
                        </div>
                      </td>
                      <td v-if="item.postulacion">
                        <div v-if="item.postulacion.tep_nominacion">
                          {{ item.postulacion.tep_nominacion.nombre }}
                        </div>
                        <div v-else></div>
                      </td>
                      <td v-else></td>
                      <td>{{ item.fecha }}</td>
                      <td>{{ item.inicio }}</td>
                      <td v-if="item.tipo_fin">{{ item.tipo_fin }}</td>
                      <td v-else>Sin Finalizar</td>
                      <td colspan="1" v-if="item.turno">
                        {{ item.turno.nombre }}
                      </td>
                      <td v-else></td>
                      <td class="text-center">
                        <span
                          class="badge"
                          :class="
                            item.estado == 1
                              ? 'badge-success'
                              : item.estado == 2
                              ? 'badge-primary'
                              : item.estado == 3
                              ? 'badge-warning'
                              : item.estado == 4
                              ? 'badge-danger'
                              : item.estado == 5
                              ? 'bg-gray'
                              : 'badge-light'
                          "
                        >
                          {{ item.nEstado }}
                        </span>
                      </td>

                      <td class="text-center">
                        <div
                          v-if="
                            item.tep_preliquidacion_internos &&
                              item.tep_preliquidacion_internos.length > 0
                          "
                        >
                          <span
                            v-for="pre in item.tep_preliquidacion_internos"
                            :key="pre.nEstado"
                            :value="pre.nEstado"
                            class="badge"
                            :class="
                              !pre
                                ? 'bg-danger'
                                : pre.estado == 1
                                ? 'bg-info'
                                : pre.estado == 2
                                ? 'bg-navy'
                                : pre.estado == 3
                                ? 'bg-success'
                                : 'badge-gray'
                            "
                          >
                            {{ pre.estado ? pre.nEstado : "No liquidado" }}
                          </span>
                        </div>
                        <div v-else>
                          <span class="badge bg-danger">No liquidado</span>
                        </div>
                      </td>

                      <td class="text-center">
                        <div v-if="item.tipo_operacion == 1">
                          <span class="badge badge-info">
                            {{ item.nEstadoOperacion }}
                          </span>
                        </div>
                        <div v-else-if="item.tipo_operacion == 2">
                          <span class="badge badge-secondary">
                            {{ item.nEstadoOperacion }}
                          </span>
                        </div>
                        <div v-else-if="item.tipo_operacion == 3">
                          <span class="badge badge-primary">
                            {{ item.nEstadoOperacion }}
                          </span>
                        </div>
                      </td>
                      <td class="text-center">
                        <div v-if="item.estado_inspeccion == 1">
                          <span class="badge badge-success">
                            {{ item.nEstadoInspeccion }}
                          </span>
                        </div>
                        <div v-else-if="item.estado_inspeccion == 2">
                          <span class="badge badge-success">
                            {{ item.nEstadoInspeccion }}
                          </span>
                        </div>
                        <div v-else-if="item.estado_inspeccion == 3">
                          <span class="badge badge-danger">
                            {{ item.nEstadoInspeccion }}
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="turnos.total">
                  <p>
                    Mostrando del <b>{{ turnos.from }}</b> al
                    <b>{{ turnos.to }}</b> de un total:
                    <b>{{ turnos.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="turnos"
                  @pagination-change-page="getIndex"
                  :limit="2"
                  class="float-right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
      <TepTurnoDetalle ref="TepTurnoDetalle" />
      <TepTurnoFinalizar ref="TepTurnoFinalizar" />
      <TepTurnoCrear ref="TepTurnoCrear" />
      <TepTurnoInspeccion ref="TepTurnoInspeccion" />
      <TepTurnoEntregarTurno ref="TepTurnoEntregarTurno" />
      <TepTurnoExport ref="TepTurnoExport" />
      <TepTurnoCancelacion ref="TepTurnoCancelacion" />
      <TepTurnoCambiarConductor ref="TepTurnoCambiarConductor" />
      <TepTurnoCambiarVehiculo ref="TepTurnoCambiarVehiculo" />
      <TepTurnoCACambioTTrans ref="TepTurnoCACambioTTrans" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import pagination from "laravel-vue-pagination";
import TepTurnoDetalle from "./TepTurnoDetalle";
import TepTurnoFinalizar from "./TepTurnoFinalizar";
import TepTurnoCrear from "./TepTurnoCrear";
import TepTurnoInspeccion from "./TepTurnoInspeccion";
import TepTurnoEntregarTurno from "./TepTurnoEntregarTurno";
import TepTurnoCambiarConductor from "./TepTurnoCambiarConductor";
import TepTurnoCambiarVehiculo from "./TepTurnoCambiarVehiculo";
import TepTurnoCACambioTTrans from "./TepTurnoCACambioTTrans";
import moment from "moment";
import TepTurnoExport from "./TepTurnoExport";
import TepTurnoCancelacion from "./TepTurnoCancelacion";
import vSelect from "vue-select";
export default {
  name: "TepTurnoIndex",
  components: {
    Loading,
    pagination,
    TepTurnoDetalle,
    TepTurnoFinalizar,
    TepTurnoCrear,
    TepTurnoInspeccion,
    TepTurnoExport,
    TepTurnoCancelacion,
    TepTurnoEntregarTurno,
    TepTurnoCambiarConductor,
    TepTurnoCambiarVehiculo,
    TepTurnoCACambioTTrans,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      turnos: {},
      selct_empresa: {},
      select_turno: {},
      filtros: {
        id_turno: null,
        conductor: null,
        conductor2: null,
        cedula2: null,
        vehiculo: null,
        fecha: null,
        sitio: null,
        estado: [],
        estadopre: null,
        tipo_operacion: null,
        estado_inspeccion: null,
        cedula: null,
        turno: null,
        inicio: null,
      },
      fechaAct: null,
      inspecciones: {},
      listasForms: {
        estados: [],
        estadospre: [],
        empresas: [],
        tipo_operacion: [],
        estado_inspeccion: [],
        turnos: [],
      },
    };
  },
  methods: {
    getIndex(page = 1) {
      this.cargando = true;

      this.filtros.empresa_id = this.selct_empresa
        ? this.selct_empresa.id
        : null;
      this.filtros.turno = this.select_turno ? this.select_turno.id : null;

      axios
        .get("/api/tep/TurnoTransporte?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.turnos = response.data;
          this.cargando = false;
        })
        .catch(function(error) {
          this.cargando = false;
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarEmpresas(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/empresas/lista?razon=" + search;
        axios
          .get(url, {
            params: {
              linea_negocio_id: [5],
            },
          })
          .then(function(response) {
            let respuesta = response.data;
            me.listasForms.empresas = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    getEstados() {
      axios.get("/api/lista/89").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    getEstadosPre() {
      axios.get("/api/lista/98").then((response) => {
        this.listasForms.estadospre = response.data;
      });
    },

    getEstadosInspeccion() {
      axios.get("/api/lista/92").then((response) => {
        this.listasForms.estado_inspeccion = response.data;
      });
    },

    getTipoOperacion() {
      axios.get("/api/lista/80").then((response) => {
        this.listasForms.tipo_operacion = response.data;
      });
    },

    getTurnos() {
      axios.get("/api/admin/turnos/lista").then((response) => {
        this.listasForms.turnos = response.data;
      });
    },

    getMsg() {
      if (this.$route.params.msg) {
        this.$swal({
          icon: "success",
          title: this.$route.params.msg,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },

    llenarModal(item) {
      if (this.$store.getters.can("tep.turnos.detalle")) {
        this.$refs.TepTurnoDetalle.llenar_modal(item);
      }
    },

    llenarModalEntregarTurno(item) {
      if (this.$store.getters.can("tep.turnos.edit")) {
        this.$refs.TepTurnoEntregarTurno.llenar_modal(item);
      }
    },

    llenarModalCambiarConductor(item) {
      if (this.$store.getters.can("tep.turnos.cambiarConductor")) {
        this.$refs.TepTurnoCambiarConductor.llenar_modal(item);
      }
    },

    llenarModalCambiarVehiculo(item) {
      if (this.$store.getters.can("tep.turnos.cambiarVehiculo")) {
        this.$refs.TepTurnoCambiarVehiculo.llenar_modal(item);
      }
    },

    llenarModalCrear() {
      if (this.$store.getters.can("tep.turnos.create")) {
        this.$refs.TepTurnoCrear.llenar_modal();
      }
    },

    llenarModalFinalizar(id, vehiculo, fecha_ini) {
      if (this.$store.getters.can("tep.turnos.finalizar")) {
        this.$refs.TepTurnoFinalizar.llenar_modal(id, vehiculo, fecha_ini);
      }
    },

    llenarModalInspeccion(turno, tipo) {
      this.$refs.TepTurnoInspeccion.llenar_modal_inspeccion(turno, tipo);
    },

    llenarModalCancelar(turno) {
      this.$refs.TepTurnoCancelacion.llenar_modal_cancelar(turno);
    },

    cambiarTipoTransporte(item, cecos_afes) {
      if (this.$store.getters.can("tep.turnos.cambiarTipoTransporte")) {
        const dataTurno = item;
        this.$swal({
          icon: "warning",
          title: `Está seguro de cambiar este Turno #${item.id} a un servicio Nacional?`,
          html: `
            <div>Postulación: ${
              item.postulacion && item.postulacion.tep_nominacion
                ? item.postulacion.tep_nominacion.nombre
                : ""
            }</div>
            <div>Sitio: ${item.sitio.nombre}</div>
            <div>Placa: ${item.vehiculo.placa}</div>
            <div>Conductor: ${item.conductor.nombres} ${
            item.conductor.apellidos
          }</div>
          `,
          text: "No podrá revertir esta acción",
          showCancelButton: true,
          confirmButtonText: "Si, Cambiar!",
        }).then((result) => {
          if (result.value) {
            this.saveCambiarTipoTransporte(dataTurno, cecos_afes);
          }
        });
      }
    },

    saveCambiarTipoTransporte(dataTurno, cecos_afes) {
      this.cargando = true;
      const me = this;
      const data = {
        turno: dataTurno,
        cecos_afes,
      };
      axios
        .put("/api/tep/turno/cambiarTipoTransporte", {
          params: data,
        })
        .then((data) => {
          this.cargando = false;
          this.getIndex();
          this.$swal({
            icon: "success",
            title: `Turno cambiado a nacional exitosamente, puede consultarlo con el numero de servicio ${data.data.viaje}`,
            showConfirmButton: true,
          });
          this.$refs.TepTurnoCACambioTTrans.$refs.closeModal1.click();
        })
        .catch(function(error) {
          me.cargando = false;
          me.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    editOdometroInicial(turno) {
      return this.$router.push({
        name: "/Tep/TurnoOdoInicial",
        params: { accion: "Inicial", data_turno: turno, id: turno.id },
      });
    },

    editOdometroFinal(turno) {
      return this.$router.push({
        name: "/Tep/TurnoOdoFinal",
        params: { accion: "Final", data_turno: turno, id: turno.id },
      });
    },

    fechaActual() {
      this.fechaAct = moment().format("YYYY-MM-DD HH:mm:ss");
      this.fechaCorte = moment(this.fechaAct)
        .add(-1, "hours")
        .format("YYYY-MM-DD HH:mm:ss");
    },

    pivotTable() {
      return this.$router.push({
        name: "/Tep/TurnosPivotTable",
      });
    },
  },
  mounted() {
    this.getIndex();
    this.getEstados();
    this.getEstadosPre();
    this.getMsg();
    this.getEstadosInspeccion();
    this.getTipoOperacion();
    this.fechaActual();
    this.getTurnos();
  },
};
</script>
